import React from "react";
import {
    Button,
    IconButton,
    Slider,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary
  } from "@mui/material";
  import CloseIcon from "@mui/icons-material/Close";
  import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
  import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
  import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
  import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
  import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import "./style.scss";

const SidebarInfo =({onClose})=>{
return(
    <div className="sidebar-info">
      <div className="sidebar-info__header">
      <Typography variant="h5">Best practices</Typography>
        <IconButton color="primary" size="small" onClick={onClose}>
          <CloseIcon size="small" />
        </IconButton>
      </div>
      <div className="sidebar-fine-tune__box">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <StarBorderOutlinedIcon className="sidebar-fine-tune__box__icon"/>
<Typography variant="body1">
  Optimal Output
  </Typography>     
     </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h3">
          Use High-Quality Product Pages
          </Typography>
         <Typography variant="body1">Did you know? The quality of the images on the product page directly affects the visuals our tool generates. High-resolution images help us create sharper, more professional visuals.</Typography>
         
        </AccordionDetails>
        <AccordionDetails>
          <Typography variant="h3">
          Provide Direct Product URLs 
          </Typography>
         <Typography variant="body1"> Best Practice: Make sure the URL you provide links directly to the product page (not a category or homepage) to ensure our system pulls the most relevant content for generating visuals.</Typography>
         
        </AccordionDetails>
        <AccordionDetails>
          <Typography variant="h3">
          Provide Direct Product URLs 
          </Typography>
         <Typography variant="body1"> Best Practice: Make sure the URL you provide links directly to the product page (not a category or homepage) to ensure our system pulls the most relevant content for generating visuals.</Typography>
         
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          
        >
          <LinkOutlinedIcon className="sidebar-fine-tune__box__icon" />
<Typography variant="body1">
Tips for enhanced visuals  </Typography>     
     </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h3">
          Use High-Quality Product Pages
          </Typography>
         <Typography variant="body1">Did you know? The quality of the images on the product page directly affects the visuals our tool generates. High-resolution images help us create sharper, more professional visuals.</Typography>
         
        </AccordionDetails>
        <AccordionDetails>
          <Typography variant="h3">
          Provide Direct Product URLs 
          </Typography>
         <Typography variant="body1"> Best Practice: Make sure the URL you provide links directly to the product page (not a category or homepage) to ensure our system pulls the most relevant content for generating visuals.</Typography>
         
        </AccordionDetails>
        <AccordionDetails>
          <Typography variant="h3">
          Provide Direct Product URLs 
          </Typography>
         <Typography variant="body1"> Best Practice: Make sure the URL you provide links directly to the product page (not a category or homepage) to ensure our system pulls the most relevant content for generating visuals.</Typography>
         
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          
        >
          <ColorLensOutlinedIcon className="sidebar-fine-tune__box__icon" />
<Typography variant="body1">
Different visual styles </Typography>     
     </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h3">
          Use High-Quality Product Pages
          </Typography>
         <Typography variant="body1">Did you know? The quality of the images on the product page directly affects the visuals our tool generates. High-resolution images help us create sharper, more professional visuals.</Typography>
         
        </AccordionDetails>
        <AccordionDetails>
          <Typography variant="h3">
          Provide Direct Product URLs 
          </Typography>
         <Typography variant="body1"> Best Practice: Make sure the URL you provide links directly to the product page (not a category or homepage) to ensure our system pulls the most relevant content for generating visuals.</Typography>
         
        </AccordionDetails>
        <AccordionDetails>
          <Typography variant="h3">
          Provide Direct Product URLs 
          </Typography>
         <Typography variant="body1"> Best Practice: Make sure the URL you provide links directly to the product page (not a category or homepage) to ensure our system pulls the most relevant content for generating visuals.</Typography>
         
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          
        >
          <StorefrontOutlinedIcon className="sidebar-fine-tune__box__icon" />
<Typography variant="body1">
Boost your e-commerce  </Typography>     
     </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h3">
          Use High-Quality Product Pages
          </Typography>
         <Typography variant="body1">Did you know? The quality of the images on the product page directly affects the visuals our tool generates. High-resolution images help us create sharper, more professional visuals.</Typography>
         
        </AccordionDetails>
        <AccordionDetails>
          <Typography variant="h3">
          Provide Direct Product URLs 
          </Typography>
         <Typography variant="body1"> Best Practice: Make sure the URL you provide links directly to the product page (not a category or homepage) to ensure our system pulls the most relevant content for generating visuals.</Typography>
         
        </AccordionDetails>
        <AccordionDetails>
          <Typography variant="h3">
          Provide Direct Product URLs 
          </Typography>
         <Typography variant="body1"> Best Practice: Make sure the URL you provide links directly to the product page (not a category or homepage) to ensure our system pulls the most relevant content for generating visuals.</Typography>
         
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          
        >
          <StarBorderOutlinedIcon className="sidebar-fine-tune__box__icon" />
<Typography variant="body1">
Tips for enhanced visuals  </Typography>     
     </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h3">
          Use High-Quality Product Pages
          </Typography>
         <Typography variant="body1">Did you know? The quality of the images on the product page directly affects the visuals our tool generates. High-resolution images help us create sharper, more professional visuals.</Typography>
         
        </AccordionDetails>
        <AccordionDetails>
          <Typography variant="h3">
          Provide Direct Product URLs 
          </Typography>
         <Typography variant="body1"> Best Practice: Make sure the URL you provide links directly to the product page (not a category or homepage) to ensure our system pulls the most relevant content for generating visuals.</Typography>
         
        </AccordionDetails>
        <AccordionDetails>
          <Typography variant="h3">
          Provide Direct Product URLs 
          </Typography>
         <Typography variant="body1"> Best Practice: Make sure the URL you provide links directly to the product page (not a category or homepage) to ensure our system pulls the most relevant content for generating visuals.</Typography>
         
        </AccordionDetails>
      </Accordion>
      </div>

    </div>
  );
}
export default SidebarInfo;