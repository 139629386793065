import React, { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  TextField,
  Slider,
  Typography,
} from "@mui/material";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import CloseIcon from "@mui/icons-material/Close";
import PhotoSizeSelectLargeIcon from "@mui/icons-material/PhotoSizeSelectLarge";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import "./style.scss";

const SidebarFineTune = ({ item,activeImage, setActiveImage, onClose, onSubmit }) => {
 const [format, setFormat] = useState("square");
  const [xAxis, setXAxis] = useState(50);
  const [yAxis, setYAxis] = useState(50);
  const [scale, setScale] = useState(70);
  const [promptContent, setPromptContent] = useState([]);

  useEffect(() => {
    if (item.imagePrompts) {
      const allPrompts = item.imagePrompts.map(img => img.prompt);
      setPromptContent(allPrompts);
    }
  }, [item.imagePrompts]);

  const handlePromptChange = (index, value) => {
    const updatedPrompts = [...promptContent];
    updatedPrompts[index] = value;
    setPromptContent(updatedPrompts);
  };

  return (
    <div className="sidebar-fine-tune">
      <div className="sidebar-fine-tune__header">
        <div className="sidebar-fine-tune__header__title">{item.title}</div>
        <IconButton color="primary" size="small" onClick={onClose}>
          <CloseIcon size="small" />
        </IconButton>
      </div>

      <div className="sidebar-fine-tune__box">
        <div className="sidebar-fine-tune__box__title">
          <ImageSearchIcon
            fontSize="24"
            className="sidebar-fine-tune__box__icon"
          />
          Select image
        </div>
        <div className="sidebar-fine-tune__box__content">
          <div className="sidebar-fine-tune__box__product-images">
            {item?.pageData?.productImages?.map((imageItem, i) => (
              <div
                key={i}
                className={
                  "sidebar-fine-tune__box__product-images__item " +
                  (i === activeImage ? "active" : "")
                }
                onClick={() => setActiveImage(i)}
              >
                <img
                  className="sidebar-fine-tune__box__product-images__content"
                  src={imageItem.src}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="sidebar-fine-tune__box">
  <div className="sidebar-fine-tune__box__title">
    <ShortcutIcon
      fontSize="24"
      className="sidebar-fine-tune__box__icon"
    />
    Prompts
  </div>
  <div className="sidebar-fine-tune__box__content">
    <TextField
      multiline
      rows={4}
      onChange={(e) => handlePromptChange(0, e.target.value)}
      value={promptContent[0] || ""}
      fullWidth
      variant="outlined"
      label="image 1"
      size="small"
      InputProps={{
        style: {
          fontSize: 14,
        },
      }}
    />
    {promptContent.slice(1).map((prompt, index) => (
      <TextField
        key={index + 1}
        multiline
        rows={4}
        label={`image ${index + 2}`}
        onChange={(e) => handlePromptChange(index + 1, e.target.value)}
        value={prompt}
        fullWidth
        variant="outlined"
        size="small"
        InputProps={{
          style: {
            fontSize: 14,
          },
        }}
      />
    ))}
  </div>
</div>


    
      

      <div className="sidebar-fine-tune__box">
        <div className="sidebar-fine-tune__box__title">
          <PhotoSizeSelectLargeIcon
            fontSize="24"
            className="sidebar-fine-tune__box__icon"
          />
          Output settings
        </div>
        <div className="sidebar-fine-tune__box__content">
          <ToggleButtonGroup
            color="primary"
            value={format}
            exclusive
            onChange={(e) => setFormat(e.target.value)}
            fullWidth
            size="small"
            sx={{
              mb: 2,
            }}
          >
            <ToggleButton value="square">Square</ToggleButton>
            <ToggleButton value="portrait">Portrait</ToggleButton>
            <ToggleButton value="landscape">Landscape</ToggleButton>
          </ToggleButtonGroup>

          <Typography fontSize="small" gutterBottom>
            Position on X-axis
          </Typography>
          <Slider
            size="small"
            defaultValue={50}
            min={0}
            max={100}
            value={xAxis}
            valueLabelDisplay="auto"
            onChange={(e, value) => setXAxis(value)}
          />

          <Typography fontSize="small" gutterBottom>
            Position on Y-axis
          </Typography>
          <Slider
            size="small"
            defaultValue={50}
            min={0}
            max={100}
            value={yAxis}
            valueLabelDisplay="auto"
            onChange={(e, value) => setYAxis(value)}
          />

          <Typography fontSize="small" gutterBottom>
            Scale
          </Typography>
          <Slider
            size="small"
            defaultValue={50}
            min={0}
            max={100}
            value={scale}
            valueLabelDisplay="auto"
            onChange={(e, value) => setScale(value)}
          />
        </div>
      </div>
    
      <div className="sidebar-fine-tune__footer">
        <Button
          variant="contained"
          fullWidth
          sx={{
            boxShadow: "none",
          }}
          onClick={() =>
            onSubmit({
              ...item,
              productImage: item?.pageData?.productImages[activeImage]?.src,
              prompt: promptContent,
              format: format,
              xAxis: xAxis,
              yAxis: yAxis,
              image_1: undefined,
              image_2: undefined,
              image_3: undefined,
              image_4: undefined,
              imagePrompts: promptContent.map(prompt => ({ prompt })),
            })
          }
        >
          Generate
        </Button>
      </div>
    </div>
  );
};

export default SidebarFineTune;
