import React from 'react';
import { Button, TextField, IconButton, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import LinkIcon from '@mui/icons-material/Link';
import TuneIcon from '@mui/icons-material/Tune';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SidebarSetting from '../../SidebarSetting';
import SidebarInfo from '../SidebarInfo';

import './style.scss';

const ToolTopBar = ({ onSubmit }) => {
    const [url, setUrl] = React.useState('');
    const [showSettingItem, setShowSettingItem] = React.useState(false);
    const [sidebarSettings, setSidebarSettings] = React.useState({
        format: 'square',
        xAxis: 50,
        yAxis: 50,
        scale: 70
    });
    const [showInfo, setShowInfo] = React.useState(false);
    const [isValid, setIsValid] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openMenu, setOpenMenu] = React.useState(false);

    //mobile friendly
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleButton = (value) => {
        let urlRegex = new RegExp('^(http|https)://[^\\s/$.?#].[^\\s]*$', 'i');
        let isValid = urlRegex.test(url);
        setIsValid(isValid);
        if (!isValid) {
            return;
        }
        setUrl('');
        onSubmit({
            url: url,
            ...sidebarSettings
        });
        window.scrollTo(0, 0);
    };

    const errorMessage = 'Please enter a valid URL.';

    const handleSettingSidebarSave = (updatedSettings) => {
        setSidebarSettings(updatedSettings);
        setShowSettingItem(false);
        setShowInfo(false);
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenMenu(true);
    };

    const handleMenuClose = () => {
        setOpenMenu(false);
    };

    return (
        <div className="tool-top-bar">
            {showSettingItem && <SidebarSetting item={sidebarSettings} onSubmit={handleSettingSidebarSave} onClose={() => setShowSettingItem(false)} />}
            {showInfo && <SidebarInfo onClose={() => setShowInfo(false)} />}

            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleButton(url);
                }}
                style={{ display: 'flex', width: '100%' }}>
                <TextField
                    placeholder="e.g. https://www.webshop.com/product-1"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={url}
                    error={!isValid}
                    helperText={!isValid ? errorMessage : ''}
                    onChange={(e) => setUrl(e.target.value)}
                    sx={{ mr: 1 }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LinkIcon />
                            </InputAdornment>
                        )
                    }}
                    autoFocus
                />
                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        boxShadow: 'none',
                        height: '40px'
                    }}
                    onClick={() => handleButton(url)}>
                    Generate
                </Button>

                {isSmallScreen ? (
                    <IconButton onClick={handleMenuClick} sx={{ boxShadow: 'none' }}>
                        <MoreVertIcon />
                    </IconButton>
                ) : (
                    <>
                        <Button
                            onClick={() => setShowSettingItem(true)}
                            sx={{
                                boxShadow: 'none',
                                height: '40px'
                            }}>
                            <TuneIcon />
                        </Button>
                        <Button onClick={() => setShowInfo(true)}>
                            <InfoOutlinedIcon />
                        </Button>
                    </>
                )}
            </form>

            <Menu
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                sx={{
                    mt: 1
                }}>
                <MenuItem
                    onClick={() => {
                        setShowSettingItem(true);
                        handleMenuClose();
                    }}>
                    <TuneIcon sx={{ mr: 1 }} /> Settings
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setShowInfo(true);
                        handleMenuClose();
                    }}>
                    <InfoOutlinedIcon sx={{ mr: 1 }} /> Info
                </MenuItem>
            </Menu>
        </div>
    );
};

export default ToolTopBar;
