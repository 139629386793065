import axios from 'axios';

const host = 'https://adgenai-acceptance.api.bycape.io/';
const hostAI = 'https://cape-v1-api.ngrok.pizza/process';

export const parsePage = async (url) => {
    try {
        const response = await axios.post(host + '/parser/parse', {
            url: url
        });
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const generateCopy = async (data) => {
    try {
        const response = await axios.post(host + '/generate-copy/generate', data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const warmup = async () => {};
export { hostAI };
