import React, { useEffect, useState, useRef } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './style.scss';

const FullscreenImage = ({ nr, item, onClose }) => {
    const images = [item.image_1, item.image_2, item.image_3, item.image_4].filter(Boolean);
    const [currentIndex, setCurrentIndex] = useState(nr - 1);
    const focusRef = useRef();
    useEffect(() => {
        focusRef.current.focus();
    }, []);
    const nextImage = () => {
        if (currentIndex < images.length - 1) {
            setCurrentIndex((prevIndex) => prevIndex + 1);
        }
    };

    const prevImage = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prevIndex) => prevIndex - 1);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowRight') {
            nextImage();
        } else if (event.key === 'ArrowLeft') {
            prevImage();
        }
    };

    return (
        <div
            className="full-screen-image"
            ref={focusRef}
            tabIndex="0"
            onKeyDown={handleKeyDown} // Handles keyboard navigation
        >
            <div className="full-screen-image__header">
                <div className="full-screen-image__header__title">{item.title}</div>
                <IconButton color="primary" size="small" onClick={onClose}>
                    <CloseIcon size="small" />
                </IconButton>
            </div>

            <div className="full-screen-image__main" autoFocus>
                {images.length > 0 && (
                    <>
                        <IconButton color="primary" size="small" onClick={prevImage} disabled={currentIndex === 0}>
                            <ArrowBackIosIcon />
                        </IconButton>
                        <div className="full-screen-image__main-image">{images[currentIndex] && <img src={images[currentIndex]} alt={`Image ${currentIndex + 1}`} />}</div>
                        <IconButton color="primary" size="small" onClick={nextImage} disabled={currentIndex === images.length - 1}>
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </>
                )}
            </div>

            <div className="full-screen-image__thumbnails">
                {images.map((thumbnail, index) => thumbnail && <img key={index} src={thumbnail} alt={`Thumbnail ${index + 1}`} className={`thumbnail ${index === currentIndex ? 'active' : ''}`} onClick={() => setCurrentIndex(index)} />)}
            </div>
        </div>
    );
};

export default FullscreenImage;
