import React, { useState, useEffect, useRef } from 'react';
import ToolTopBar from './components/ToolTopBar';
import ListItem from './components/ListItem';
import SidebarFineTune from './components/SidebarFineTune';
import emptystate from './assets/empty-state.png';

// import ShowLimitPopup from './components/ShowlimitPopup';
import './style.scss';
import { createImages, generateCopy, parsePage } from '../../helpers/requests';
import { Typography, Modal, Box, IconButton, TextField, Button } from '@mui/material';
import { hostAI } from '../../helpers/requests';

const ToolController = () => {
    const getStorageData = () => {
        let list = localStorage.getItem('overview') ? JSON.parse(localStorage.getItem('overview')) : [];

        if (list.length > 0) {
            list.forEach((item) => {
                if (item.loading) {
                    item.loading = false;
                }
            });
        }

        return list;
    };

    const [overview, setOverview] = React.useState(getStorageData());
    const [showFinetuneItem, setShowFinetuneItem] = React.useState(false);
    const [activeImage, setActiveImage] = useState(0);
    const cancelGenerationRef = useRef(null);
    const triggeredSubmitRef = useRef(false);
    const loading = useRef(false);
    // const [showLimitPopup, setShowLimitPopup] = useState(false);
    // const [hasSubmitted, setHasSubmitted] = useState(false);

    /**
     * Cancle image generation
     */

    const cancelImageGeneration = (uuid) => {
        cancelGenerationRef.current = true;

        // Immediately stop loading and reset progress for the item
        updateItem(uuid, {
            loading: false,
            loadingProgress: 0
        });
    };

    /**
     * Upon loading compoenent, check if there is an import
     */
    React.useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const importData = urlParams.get('import');
        const url = urlParams.get('url');
        cancelGenerationRef.current = false;

        if (importData) {
            let importedData = JSON.parse(atob(importData));
            handleImport(importedData);
        }
        if (url && !loading.current) {
            loading.current = true;
            // Check if not already done
            if (!overview.find((item) => item.url === url)) {
                handleSubmit({ url: url });
            } else {
                if (!triggeredSubmitRef.current) {
                    handleSubmit({ url: url });
                }
                triggeredSubmitRef.current = true;
            }
        }
    }, []);

    /**
     * Import data upon load
     * @param {*} importedData
     */
    const handleImport = (importedData) => {
        setOverview((prev) => {
            let newObject = [importedData, ...prev];
            localStorage.setItem('overview', JSON.stringify(newObject));
            return newObject;
        });
    };

    /**
     * Handle submitting of new item
     * @param {*} newObj
     */
    const handleSubmit = (newObj) => {
        // if (!hasSubmitted && overview.length > 1) {
        //     setShowLimitPopup(true);
        //     return;
        // }

        setOverview((prev) => {
            const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => (c === 'x' ? (Math.random() * 16) | 0 : ((Math.random() * 16) & 0x3) | 0x8).toString(16));

            newObj.uuid = uuid;
            if (!newObj.title) {
                newObj.title = 'Product ' + (prev.length + 1);
            }
            newObj.loading = true;
            newObj.loadingProgress = 0;
            let newObject = [newObj, ...prev];
            localStorage.setItem('overview', JSON.stringify(newObject));
            loadPageData(newObj);
            return newObject;
        });
    };

    /**
     * Handle remove of item
     * @param {*} uuid
     */
    const handleRemove = (uuid) => {
        setOverview((prev) => {
            let newOverview = prev.filter((item) => item.uuid !== uuid);
            localStorage.setItem('overview', JSON.stringify(newOverview));
            return newOverview;
        });
    };

    /**
     * Handle tinetune
     * @param {*} item
     */
    const handleFinetune = (item) => {
        setShowFinetuneItem(item);
    };
    /**
     * Handle one generate
     * @param {*} item
     */
    const handleOneGenerate = (item) => {
        console.log('item', item);
        setShowFinetuneItem(false);
        delete item.imagePrompts;
        generatePrompts(item, 1);
        window.scrollTo(0, 0);
    };
    /**
     * Regenerate item
     * @param {*} item
     */
    const handleRegenerate = (item) => {
        handleSubmit(item);
        setShowFinetuneItem(false);
        window.scrollTo(0, 0);
    };

    /**
     * Start loading page
     * @param {*} newObj
     */
    const loadPageData = async (inputData) => {
        let newData = inputData;

        // New page
        if (!inputData?.pageData?.title) {
            let pageData = await parsePage(inputData.url);

            if (!pageData || !pageData.description || !pageData.productImages) {
                updateItem(inputData.uuid, {
                    error: true,
                    loading: false,
                    errorMessage: 'Could not fetch data'
                });
            } else {
                let newData = {
                    ...inputData,
                    title: pageData?.title,
                    productImage: pageData.productImages[0]?.src,
                    pageData: pageData,
                    loadingProgress: 1
                };

                updateItem(newData.uuid, newData);
                await generatePrompts(newData);
            }
        }
        // Regenerate, we have page data
        else {
            await generatePrompts(newData);
        }
    };

    /**
     *
     * Generate prompts with AI
     * @param {*} newData
     */
    const generatePrompts = async (newData, nrOfImages = 4) => {
        if (newData.imagePrompts && newData.imagePrompts.length > 0) {
            updateItem(newData.uuid, {
                loadingProgress: 2
            });
            cancelGenerationRef.current = false;
            await generateImage(newData, 1, undefined, nrOfImages);
        } else {
            let result = await generateCopy({
                title: newData.pageData.title,
                description: newData.pageData.description,
                imageUrl: newData.productImage
            });

            if (result?.imagePrompts) {
                updateItem(newData.uuid, {
                    imagePrompts: result.imagePrompts,
                    loadingProgress: 2
                });
                cancelGenerationRef.current = false;
                generateImage({ ...newData, imagePrompts: result.imagePrompts }, 1, undefined, nrOfImages);
            }
        }
    };

    /**
     * Generate image
     * @param {*} newData
     * @param {*} nr
     */

    const generateImage = (inputData, nr, totalTime = 0) => {
        const startTime = Date.now();
        if (cancelGenerationRef.current || inputData.loadingProgress >= 2) {
            console.log('Image generation canceled or already completed.');
            updateItem(inputData.uuid, {
                loading: false,
                loadingProgress: 0
            });
            return;
        }

        let requestData = {
            'x-axis': inputData.xAxis || 50,
            'y-axis': inputData.yAxis || 50,
            scale: inputData.scale / 100 || 0.6,
            image_url: inputData.productImage,
            prompt: inputData.imagePrompts[nr - 1]?.prompt,
            aspect_ratio: 'square'
        };
        const timePassed = (Date.now() - startTime) / (1000 * 60);
        totalTime += timePassed;
        fetch(hostAI, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        })
            .then((response) => {
                // Get the readable stream from the response body
                const stream = response.body;
                // Get the reader from the stream
                const reader = stream.getReader();
                // Define a function to read each chunk
                const readChunk = () => {
                    // Read a chunk from the reader
                    reader
                        .read()
                        .then(({ value, done }) => {
                            // Convert the chunk value to a string
                            let chunkString = new TextDecoder().decode(value);
                            // Log the chunk string
                            console.log('chunk', chunkString);
                            chunkString = chunkString.replace('data:', '');

                            if (chunkString) {
                                let message = JSON.parse(chunkString);
                                console.log('message', message);
                                if (message.status == 'success') {
                                    imageDone(inputData, message, nr);
                                } else if (message.status == 'queued') {
                                    updateItem(inputData.uuid, {
                                        queue_position: message.queue_position
                                    });
                                }
                            }

                            // Check if the stream is done
                            if (done) {
                                // Log a message
                                console.log('Stream finished');
                                // Return from the function
                                return;
                            }

                            // Read the next chunk
                            readChunk();
                        })
                        .catch((error) => {
                            // Log the error
                            console.error(error);
                        });
                };
                // Start reading the first chunk
                readChunk();
            })
            .catch((error) => {
                // Log the error
                console.error(error);
            });
    };

    const imageDone = (inputData, imageOutput, nr) => {
        if (imageOutput?.image_urls[0]) {
            updateItem(inputData.uuid, {
                loadingProgress: nr + 2,
                ['image_' + nr]: imageOutput?.image_urls[0],
                loading: nr < 4
            });
        }
        // If cancel was triggered, stop the generation process here
        if (cancelGenerationRef.current) {
            console.log('Generation canceled after the first image.');
            updateItem(inputData.uuid, {
                loading: false,
                loadingProgress: nr
            });
            return;
        }

        if (nr < 4) {
            generateImage(inputData, nr + 1);
        }
    };

    /**
     * Set data to update item
     * @param {*} uuid
     * @param {*} data
     */
    const updateItem = (uuid, data) => {
        setOverview((prev) => {
            let newOverview = prev.map((item) => {
                if (item.uuid === uuid) {
                    return { ...item, ...data };
                }
                console.log('@@item', item);
                return item;
            });
            localStorage.setItem('overview', JSON.stringify(newOverview));
            return newOverview;
        });
    };

    const handleDeleteImage = (uuid, field) => {
        updateItem(uuid, {
            [field]: undefined
        });
    };

    return (
        <div className={'tool ' + (showFinetuneItem ? 'sidebar' : '')}>
            {showFinetuneItem && <SidebarFineTune item={showFinetuneItem} onSubmit={handleRegenerate} activeImage={activeImage} setActiveImage={setActiveImage} onClose={() => setShowFinetuneItem(false)} />}

            <ToolTopBar onSubmit={handleSubmit} />

            {(!overview || overview.length === 0) && (
                <div className="tool__placeholder">
                    <img src={emptystate} alt="Empty state" />
                    <div className="tool__placeholder__text">
                        <Typography variant="h1">No generations yet!</Typography>
                        <Typography variant="body1">Generate captivating visuals from your product URL. Please enter it in the top bar above.</Typography>
                    </div>
                </div>
            )}

            <div className={'tool__list'}>
                {overview.map((item, i) => (
                    <ListItem
                        item={item}
                        key={item.uuid}
                        onRemove={() => handleRemove(item.uuid)}
                        onDeleteImage={handleDeleteImage}
                        onRegenerate={() => handleFinetune(item)}
                        onOneRegenerate={handleOneGenerate}
                        activeImage={activeImage}
                        setActiveImage={setActiveImage}
                        cancelButton={() => cancelImageGeneration(item.uuid)} // Pass the uuid here
                    />
                ))}
            </div>
            {/* <ShowLimitPopup open={showLimitPopup} onClose={() => setShowLimitPopup(false)} onGenerateMore={() => setHasSubmitted(true)} /> */}
        </div>
    );
};

export default ToolController;
